// Generated using http://paletton.com/
'use strict';

export default {
  primary: '#6937d0',
  secondary: '#432c77',
  accent1: '#b8ec00',
  accent2: '#2dd1b2',
  accent3: '#9ecdbe',
  white: '#FFFFFF',
  black: '#241f20',
  gray900: '#393435',
  gray800: '#4f4b4c',
  gray750: '#5b5758',
  gray700: '#656162',
  gray600: '#7b7879',
  gray500: '#918e8f',
  gray400: '#a7a5a5',
  gray300: '#bcbbbb',
  gray200: '#d3d2d2',
  gray100: '#e8e8e8',
  gray50: '#f3f3f3',
  primary900: '#6937d0',
  primary800: '#7a4ed5',
  primary700: '#8a63da',
  primary600: '#9b7ae0',
  primary500: '#ab90e5',
  primary400: '#bca6ea',
  primary300: '#cdbcef',
  primary200: '#ded2f5',
  primary100: '#eee8fa',
  primary50: '#f6f3fd',
  primary20: '#fcfafe',
  brand_scheme: [
    '#6937D0',
    '#B7EC00',
    '#2DD1B1',
    '#9ECDBE',
    '#432c77',
    '#4B11C2 ',
    '#89B000',
    '#04C09B',
    '#6AA692',
    '#2C165E',
    '#885EDE ',
    '#CAF538',
    '#57E0C5',
    '#D0EBE2',
    '#5D4790',
    '#AF91ED',
    '#D8F868',
    '#92F0DD',
    '#F6FDFA',
    '#8270AD',
    '#330C85',
    '#607B00',
    '#00866C',
    '#6AA692',
    '#180640',
  ],
};

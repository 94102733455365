export const getChargebeeInstance = () => {
  return Chargebee.init({
    site: 'easyredir-test',
    publishableKey: 'test_C3vkZcdOjkywMLi20DjYI98uauTTmNRES',
  });
}

export const unmountChargebeeInstance = () => {
  return Chargebee.tearDown();
}

import { Controller } from '@hotwired/stimulus';
import { enableElement, disableElement } from '@rails/ujs';
import { buildStripeObject } from 'stripe/index.js.erb';

export default class extends Controller {
  static targets = ['mountPoint', 'errorBanner', 'form', 'submitButton', 'token', 'country', 'provinceState', 'name'];

  connect() {
    if (!this.hasMountPointTarget) return; // If we don't have a mount point, do nothing. This happens when starting a trial.

    // Build and store Stripe objects
    this.stripe = buildStripeObject();
    this.elements = this.buildStripeElementsObject(this.stripe);
    this.card = this.buildStripeCardObject(this.elements);

    // Mount the Stripe UI onto the mount point
    this.card.mount(this.mountPointTarget);

    // Add event listener that manages error messages from Stripe
    this.card.addEventListener('change', (event) => this.onMountPointChange(event));

    // Clean up the HTML before Turbo caches this page
    document.addEventListener(
      'turbo:before-cache',
      () => {
        this.clearAllErrorMessages();
        this.hideErrorBanner();
        enableElement(this.formTarget);

        // Remove the event listener that manages error messages from Stripe
        this.card.removeEventListener('change', (event) => this.onMountPointChange(event));

        // Unmount Stripe Elements UI so that we don't cache stale Stripe HTML/etc.
        this.card.unmount(this.mountPointTarget);
      },
      { once: true }
    );
  }

  requestToken(event) {
    if (!this.hasMountPointTarget) return; // If we don't have a mount point, do nothing. This happens when starting a trial.

    disableElement(this.formTarget);

    event.preventDefault();
    event.stopPropagation();

    this.resetErrorMessages();
    if (this.validateCardInfo()) {
      this.createStripeToken(); // The card info passes initial validation, ask Stripe to create a token
    } else {
      enableElement(this.formTarget); // The card info is not valid, allow the user to submit again
    }
  }

  submitForm(event) {
    this.formTarget.submit();
  }

  resetErrorMessages() {
    this.clearAllErrorMessages();
    this.hideErrorBanner();
  }

  validateCardInfo() {
    let isValid = true;
    for (let target of [this.countryTarget, this.nameTarget]) {
      let targetValue = target.value.trim();
      if (targetValue === '') {
        this.setErrorBannerMessage();
        this.setErrorMessage(target, 'This information is required.');
        target.dispatchEvent(new CustomEvent('select2:reinitialize'));
        isValid = false;
      }
    }
    return isValid;
  }

  createStripeToken() {
    this.stripe
      .createToken(this.card, {
        name: this.nameTarget.value,
        address_state: this.provinceStateTarget.value,
        address_country: this.countryTarget.value,
      })
      .then((result) => {
        if (result.error) {
          // Stripe had an error creating the token, display the error and allow the user to submit again
          this.setErrorBannerMessage(result.error.message);
          enableElement(this.formTarget);
        } else {
          this.tokenTarget.value = result.token.id;
          this.formTarget.dispatchEvent(new CustomEvent('easyredir:submitStripeTokenForm'));
        }
      });
  }

  onMountPointChange(event) {
    if (event.error) {
      this.setErrorMessage(this.mountPointTarget, event.error.message);
    } else {
      this.removeErrorMessage(this.mountPointTarget);
    }
  }

  setErrorBannerMessage(message = "We've had problems processing your payment information. Check the messages shown below.") {
    this.errorBannerTarget.innerHTML = `<strong>Uh oh.</strong> ${message}`;
    $(this.errorBannerTarget).show();
  }

  hideErrorBanner() {
    $(this.errorBannerTarget).hide();
  }

  setErrorMessage(element, message) {
    $(element).parent('.form-group').addClass('form-group-invalid'); // Would love to get rid of this
    element.classList.add('is-invalid');
    element.insertAdjacentHTML('afterend', `<div id="${element.id}--error" class="invalid-feedback">${message}</div>`);
  }

  removeErrorMessage(element) {
    $(element).parent('.form-group').removeClass('form-group-invalid'); // Would love to get rid of this
    element.classList.remove('is-invalid');
    const errorMessage = document.getElementById(`${element.id}--error`);
    if (errorMessage) {
      errorMessage.parentNode.removeChild(errorMessage);
    }
  }

  clearAllErrorMessages() {
    this.removeErrorMessage(this.countryTarget);
    this.removeErrorMessage(this.provinceStateTarget);
    this.removeErrorMessage(this.nameTarget);
    this.removeErrorMessage(this.mountPointTarget);
  }

  buildStripeCardObject(stripeElementsObject) {
    return stripeElementsObject.create('card', {
      iconStyle: 'solid',
      style: {
        base: {
          color: '#27272d',
          iconColor: '#27272d',
          fontWeight: 400,
          fontFamily: 'sans-serif',
          fontSize: '15px',
          '::placeholder': {
            color: '#999',
          },
        },
        invalid: {
          color: '#c61638',
        },
      },
    });
  }

  buildStripeElementsObject(stripeObject) {
    return stripeObject.elements({
      fonts: [{ cssSrc: 'https://www.urllo-cdn.com/fonts/biennale/1.0.0/biennale.css' }],
    });
  }
}

import { Controller } from '@hotwired/stimulus';
import { enableElement, disableElement } from '@rails/ujs';
import { getChargebeeInstance, unmountChargebeeInstance } from 'chargebee/index.js.erb';

export default class extends Controller {
  static targets = ['mountPoint', 'errorBanner', 'form', 'submitButton', 'token', 'country', 'provinceState', 'name'];

  connect() {
    if (!this.hasMountPointTarget) return;

    this.chargebee = getChargebeeInstance();
    this.buildChargebeeCardComponent(this.chargebee);

    document.addEventListener(
      'turbo:before-cache',
      () => {
        this.mountPointTarget.replaceChildren();
        unmountChargebeeInstance();
      },
      { once: true }
    );
  }

  addSubmitEventListener(cardComponent) {
    this.formTarget.addEventListener('easyredir:submitValidatedForm', (event) => {
      event.preventDefault();

      cardComponent
        .tokenize({
          firstName: this.nameTarget.value,
          state: this.provinceStateTarget.value,
          countryCode: this.countryTarget.value,
        })
        .then((data) => {
          this.tokenTarget.value = data.token;
          this.submitForm();
        })
        .catch((error) => {
          this.setErrorBannerMessage(error.message);
          enableElement(this.formTarget);
        });
    });
  }

  resetErrorMessages() {
    this.removeErrorMessage(this.mountPointTarget);
    this.hideErrorBanner();
  }

  requestToken(event) {
    if (!this.hasMountPointTarget) return;

    disableElement(this.formTarget);
    event.preventDefault();
    event.stopPropagation();
    this.resetErrorMessages();

    if (this.validateFormFields()) {
      this.formTarget.dispatchEvent(new CustomEvent('easyredir:submitValidatedForm')); // Form passes initial validation. Tokenize and submit.
    } else {
      enableElement(this.formTarget); // Invalid form. Re-submit.
    }
  }

  validateFormFields() {
    let isValid = true;
    for (let target of [this.countryTarget, this.nameTarget]) {
      let targetValue = target.value.trim();
      if (targetValue === '') {
        this.setErrorBannerMessage();
        this.setErrorMessage(target, 'This information is required.');
        target.dispatchEvent(new CustomEvent('select2:reinitialize'));
        isValid = false;
      }
    }
    return isValid;
  }

  validationErrorHandler(event) {
    if (event.error) {
      this.setErrorMessage(this.mountPointTarget, event.error.message);
    } else {
      this.removeErrorMessage(this.mountPointTarget);
    }
  }

  setErrorBannerMessage(message = "We've had problems processing your payment information. Check the messages shown below.") {
    this.errorBannerTarget.innerHTML = `<strong>Uh oh.</strong> ${message}`;
    $(this.errorBannerTarget).show();
  }

  hideErrorBanner() {
    $(this.errorBannerTarget).hide();
  }

  setErrorMessage(element, message) {
    this.removeErrorMessage(element);

    element.classList.add('is-invalid');
    element.insertAdjacentHTML('afterend', `<div id="${element.id}--error" class="invalid-feedback">${message}</div>`);
  }

  removeErrorMessage(element) {
    element.classList.remove('is-invalid');
    const errorMessage = document.getElementById(`${element.id}--error`);
    if (errorMessage) {
      errorMessage.parentNode.removeChild(errorMessage);
    }
  }

  mountPointElementId() {
    return `#${this.mountPointTarget.id}`;
  }

  submitForm(event) {
    this.formTarget.submit();
  }

  cardComponentOptions() {
    return {
      locale: 'en',
      style: {
        base: {
          color: '#27272d',
          fontWeight: '400',
          fontFamily: 'sans-serif',
          fontSize: '15px',
          fontSmoothing: 'antialiased',
          iconColor: '#27272d',
          '::placeholder': {
            color: '#999',
          },
        },
        invalid: {
          color: '#c61638',
        },
      },
    };
  }

  buildChargebeeCardComponent() {
    this.chargebee.load('components').then(() => {
      let cardComponent = this.chargebee.createComponent('card', this.cardComponentOptions()).at(this.mountPointElementId());

      cardComponent.on('change', (event) => this.validationErrorHandler(event));
      cardComponent.mount();
      this.addSubmitEventListener(cardComponent);
    });
  }
}

/* rails-erb-loader-dependencies ../config/secrets.yml */

import { datadogRum } from '@datadog/browser-rum';

const datadogRumApplicationId = '2e0d0245-6862-4402-a723-684cf1f91496'
const datadogRumClientToken = 'pub08b40c284d9aa29128c0ce4a6ed50941'

if (datadogRumApplicationId && datadogRumClientToken) {
  datadogRum.init({
    applicationId: '2e0d0245-6862-4402-a723-684cf1f91496',
    clientToken: 'pub08b40c284d9aa29128c0ce4a6ed50941',
    site: 'datadoghq.com',
    env: 'staging',
    service:'er-management',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    useSecureSessionCookie: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [
      "https://dashboard.stg.easyredir.com"
    ],
    excludedActivityUrls: [
      /^https:\/\/sapi\.easyredir\.com/,
      /^https:\/\/www\.google-analytics\.com\/collect\//,
      /^https:\/\/analytics\.google\.com\/g\/collect\//,
      /^https:\/\/www\.facebook\.com\/tr\//,
      /^https:\/\/hn\.inspectlet\.com/,
      /^https:\/\/track\.hubspot\.com/,
      /^https:\/\/www\.google\.(com|ca)\/pagead\//,
      /^https:\/\/googleads\.g\.doubleclick\.net\/pagead\//,
      /^https:\/\/px\.ads\.linkedin\.com\/collect/,
    ]
  })
  datadogRum.startSessionReplayRecording();
}

document.addEventListener("turbo:load", function () {
  let $body = $('body');
  let user_id = $body.data('user-id')
  let user_name = $body.data('user-name')
  let user_email = $body.data('user-email')
  let organization_id = $body.data('organization-id')
  let organization_name = $body.data('organization-name')
  let organization_plan = $body.data('organization-plan')

  if (datadogRumApplicationId && datadogRumClientToken) {
    datadogRum.setUser({
      id: user_id,
      name: user_name,
      email: user_email
    })

    datadogRum.setGlobalContextProperty('organization', {
      id: organization_id,
      name: organization_name,
      plan: organization_plan
    })
  }
})

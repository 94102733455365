import { Controller } from '@hotwired/stimulus';
import numbro from 'numbro';
import UrlloColours from '../charts/colorschemes.urllo';
import { initializeChartDefaults } from '../charts/defaults';
import { getJSON } from './controller_helpers';

function hexToRGB(hex, alpha) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

export default class extends Controller {
  static targets = ['canvas', 'legend', 'loadingIndicator'];
  static values = {
    url: String,
    background: String,
    type: String,
  };

  initialize() {
    if (!this.isTurboPreview) {
      initializeChartDefaults();
    }
  }

  connect() {
    this.enableLoadingIndicator();

    if (!this.isTurboPreview) {
      this.chart = null;
      this.initializeChart();

      // Ensure Turbo caches do not have any rows selected
      document.addEventListener(
        'turbo:before-cache',
        () => {
          this.destroyChart();
        },
        { once: true }
      );
    }
  }

  initializeChart() {
    getJSON(this.chartDataUrl, (data) => {
      this.disableLoadingIndicator();
      this.chart = new Chart(this.canvasTarget, {
        type: this.chartType === 'horizontalBar' ? 'bar' : this.chartType,
        data: data,
        options: this.chartOptions,
      });
      if (this.hasLegendTarget) {
        this.generateLegend();
      }
    });
  }

  generateLegend() {
    const legend = this.legendTarget;
    const chart = this.chart;
    const legendEl = document.createElement('div');

    chart.legend.legendItems?.forEach((item) => {
      legendEl.innerHTML += `<span class="chart-legend-item"><span class="chart-legend-indicator" style="background-color: ${item.fillStyle}"></span>${item.text}</span>`;
    });

    legend.appendChild(legendEl);
  }

  enableLoadingIndicator() {
    this.loadingIndicatorTarget.classList.remove('hide');
  }

  disableLoadingIndicator() {
    this.loadingIndicatorTarget.classList.add('hide');
  }

  destroyChart() {
    this.chart.destroy();
  }

  get chartOptions() {
    return ((chartType) => {
      switch (chartType) {
        case 'horizontalBar':
          return {
            borderRadius: 2,
            indexAxis: 'y',
            interaction: {
              intersect: true,
              mode: 'nearest',
            },
            scales: {
              y: {
                afterUpdate: (axis) => {
                  axis.paddingTop = 0;
                },
              },
              x: {
                grid: {
                  lineWidth: 1,
                  color: this.chartBackground === 'dark' ? UrlloColours.gray900 : UrlloColours.gray200,
                  zeroLineColor: this.chartBackground === 'dark' ? UrlloColours.gray900 : UrlloColours.gray200,
                },
                ticks: {
                  callback: function (value) {
                    if (!isNaN(value)) {
                      value = numbro(value)
                        .format({
                          average: true,
                          thousandSeparated: true,
                          totalLength: 2,
                          spaceSeparated: false,
                        })
                        .toUpperCase();
                    }
                    return value;
                  },
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (item) {
                    const label = item.dataset.label || '';
                    let xLabel = item.formattedValue;
                    let content = '';

                    if (item.chart.data.datasets.length > 1) {
                      content += `<span class="popover-body-label mr-auto">${label}</span>`;
                    }

                    if (!isNaN(xLabel)) {
                      xLabel = numbro(xLabel).format({
                        thousandSeparated: true,
                      });
                    }

                    content += `<span class="popover-body-value">${xLabel}</span>`;
                    return content;
                  },
                },
              },
            },
          };
        case 'bar':
          return {
            interaction: {
              intersect: true,
              mode: 'index',
            },
            onHover: function (e, elements, chart) {
              const dataTable = document.getElementById('analytics-data-table');
              if (elements.length > 0) {
                const activePoint = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false)[0];

                // ignore duplicate events as user hovers over
                // the same section.
                if (chart.data.datasets[activePoint.datasetIndex].hovered == activePoint.index) {
                  return true;
                }

                // Clear highlighted rows from the data table
                if (dataTable) {
                  const tableRows = Array.from(dataTable.querySelectorAll('tr'));
                  tableRows.forEach(function (row) {
                    row.classList.remove('data-row-highlighted');
                  });
                }

                if (activePoint) {
                  const data = chart.data;
                  const datasetIndex = activePoint.datasetIndex;
                  const dataRowId = data.datasets[datasetIndex].dataRowId;

                  // Highlight the selected row
                  if (dataTable && dataRowId) {
                    const highlightableDataRow = document.getElementById(`data-row-${dataRowId}`);
                    if (highlightableDataRow) {
                      highlightableDataRow.classList.add('data-row-highlighted');
                    }
                  }
                }

                // Mute all other points
                for (let i = 0; i < chart.data.datasets.length; i++) {
                  chart.data.datasets[i].origBackgroundColor = chart.data.datasets[i].origBackgroundColor || elements[i].element.options.backgroundColor;
                  chart.data.datasets[i].backgroundColor = Array(chart.data.datasets[i].data.length).fill(
                    hexToRGB(chart.data.datasets[i].origBackgroundColor, 0.3)
                  );

                  // clear any hovered setting already
                  delete chart.data.datasets[i].hovered;
                }

                // track which element is now hovered
                chart.data.datasets[activePoint.datasetIndex].hovered = activePoint.index;

                const bgColor = chart.data.datasets[activePoint.datasetIndex].origBackgroundColor;

                chart.data.datasets[activePoint.datasetIndex].backgroundColor = Array(chart.data.datasets[0].data.length).fill(bgColor);
                chart.data.datasets[activePoint.datasetIndex].backgroundColor[activePoint.index] = chart.tooltip.options.highlightColor || UrlloColours.accent1;

                chart.update();
              } else {
                // clear highlighted rows
                if (dataTable) {
                  const tableRows = Array.from(dataTable.querySelectorAll('tr'));
                  tableRows.forEach(function (row) {
                    row.classList.remove('data-row-highlighted');
                  });
                }

                for (let i = 0; i < chart.data.datasets.length; i++) {
                  delete chart.data.datasets[i].backgroundColor;
                  delete chart.data.datasets[i].hovered;
                }
                $('#chart-tooltip').css('display', 'none');
                chart.update();
              }
            },
            plugins: {
              tooltip: {
                enabled: false,
                mode: 'point',
                intersect: false,
                displayColors: false,
                hideIndicator: true,
                showFooterSummary: true,
                highlightColor: UrlloColours.accent1,
              },
            },
            cornerRadius: 0,
            scales: {
              x: { stacked: true },
              y: {
                stacked: true,
                grid: {
                  lineWidth: 1,
                  color: this.chartBackground === 'dark' ? UrlloColours.gray900 : UrlloColours.gray200,
                  zeroLineColor: this.chartBackground === 'dark' ? UrlloColours.gray900 : UrlloColours.gray200,
                },
                ticks: {
                  callback: function (value) {
                    if (!isNaN(value)) {
                      value = numbro(value)
                        .format({
                          average: true,
                          thousandSeparated: true,
                          totalLength: 2,
                          spaceSeparated: false,
                        })
                        .toUpperCase();
                    }
                    return value;
                  },
                },
              },
            },
          };
        case 'line':
          return {
            scales: {
              y: {
                suggestedMin: 0,
                suggestedMax: 50,
                grid: {
                  lineWidth: 1,
                  color: this.chartBackground === 'dark' ? UrlloColours.gray900 : UrlloColours.gray200,
                  zeroLineColor: this.chartBackground === 'dark' ? UrlloColours.gray900 : UrlloColours.gray200,
                },
                ticks: {
                  callback: function (value) {
                    if (!isNaN(value)) {
                      value = numbro(value)
                        .format({
                          average: true,
                          thousandSeparated: true,
                          totalLength: 2,
                          spaceSeparated: false,
                        })
                        .toUpperCase();
                    }
                    return value;
                  },
                },
              },
            },
          };
        case 'doughnut':
        case 'pie':
          return {};
        default:
          return {};
      }
    })(this.chartType);
  }

  get chartDataUrl() {
    return this.urlValue;
  }

  get isTurboPreview() {
    return document.documentElement.hasAttribute('data-turbo-preview');
  }

  get chartBackground() {
    return this.hasBackgroundValue ? this.backgroundValue : null;
  }

  get chartType() {
    return this.hasTypeValue ? this.typeValue : 'line';
  }
}
